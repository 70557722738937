<template>
	<div class="container-right">
		<div v-if="tabIdx=='tab1'">
			<div class="pageRNenter" v-if="!obligeeType">
				<div class="container-right-name">
					实名认证
					<div class="line"></div>
				</div>
				<div class="block_tab">
					<div class="tab">
						<div class="ant-btn ant-btn-primary" @click="tabIdx='tab2'">
							个人身份认证
						</div>
					</div>
					<div class="tab">
						<div class="ant-btn ant-btn-primary" @click="tabIdx='tab3'">
							企业身份认证
						</div>
					</div>
					<div class="clear"></div>
				</div>

				<div class="blockReminder">
					<div class="h3">上传证件要求：</div>
					<div class="description" slot="description">
						1、每个账号仅可关联一个实名个人或一个企业；<br>
						2、个人认证：需要提交个人身份证复印件进行确认您的身份，用于确权、版权登记等平台业务的授权；<br>
						3、企业认证：您需要提交企业营业执照、企业授权书；<br>
					</div>
				</div>
			</div>
			<div class="resultPersonal" v-if="obligeeType=='user'">
				<div class="container-right-name">
					实名认证
					<div class="line"></div>
				</div>
				<div class="MainStepsForm">
					<div class="formWrap">
						<el-form ref="form" label-width="140px">
							<el-form-item label="身份证" prop="">
								<div class="IDcardwrap">
									<div class="IDcard fl">
										<img :src="userAuthInfo.identityPicture.additionalProp1" />
									</div>
									<div class="IDcard fl">
										<img :src="userAuthInfo.identityPicture.additionalProp2" />
									</div>
									<div class="clear"></div>
								</div>
							</el-form-item>
							<el-form-item label="姓名">
								<el-input :disabled="true" v-model="userAuthInfo.userName"></el-input>
							</el-form-item>
							<el-form-item label="身份证号">
								<el-input v-model="userAuthInfo.uniqueCode" :disabled="true"></el-input>
							</el-form-item>
						</el-form>
					</div>
				</div>
			</div>
			<div class="resultCompany" v-if="obligeeType=='company'">
				<div class="container-right-name">
					实名认证
					<div class="line"></div>
				</div>
				<div class="MainStepsForm">
					<div class="formWrap">
						<el-form ref="form" :model="form" label-width="180px">
							<el-form-item label="营业执照" prop="">
								<div class="BusinessLicensewrap">
									<div class="BusinessLicense">
										<img :src="userAuthInfo.identityPicture.additionalProp1" />
									</div>
								</div>
							</el-form-item>
							<el-form-item label="统一社会信用代码" prop="id">
								<el-input v-model="userAuthInfo.uniqueCode" :disabled="true"></el-input>
							</el-form-item>

							<el-form-item label="企业名称" prop="companyName">
								<el-input v-model="userAuthInfo.companyName" :disabled="true"></el-input>
							</el-form-item>
							<el-form-item label="法人姓名" prop="name">
								<el-input v-model="userAuthInfo.userName" :disabled="true"></el-input>
							</el-form-item>
						</el-form>
					</div>
				</div>
			</div>
		</div>
		<div v-if="tabIdx=='tab2'">
			<personal @set-tab-idx="setTabIdx"></personal>
		</div>
		<div v-if="tabIdx=='tab3'">
			<company @set-tab-idx="setTabIdx"></company>
		</div>
	</div>
</template>

<script>
	import {
		mapGetters
	} from 'vuex'
	import Company from './components/Company.vue'
	import Personal from './components/Personal'
	import {
		queryObligeeList
	} from '@/api/ObligeeService'
	export default {
		name: 'RealName',
		components: {
			Company,
			Personal
		},
		computed: {
			...mapGetters(['mid', 'userInfo', 'authInfo'])
		},
		data() {
			return {
				form: {},
				tabIdx: 'tab1',
				obligeeType: null,
				userAuthInfo: null
			}
		},
		created() {
			this.getAuthInfo();
		},

		methods: {
			setTabIdx(tabIdx) {
				this.tabIdx = tabIdx
			},
			getAuthInfo() {
				let that = this;
				if (this.authInfo) {
					that.setAuthInfo(this.authInfo);
				} else {
					//查询是否实名认证
					queryObligeeList(1, 1).then(({
						data
					}) => {
						// console.log(data.list.length);
						if (data && data.list.length > 0) {
							that.$store.commit('setAuthInfo', data.list[0]);
							that.setAuthInfo(data.list[0]);
						}

					})
				}
			},
			setAuthInfo(authInfo) {
				this.userAuthInfo = authInfo;
				if (authInfo.obligeeType == '1') {

					this.obligeeType = 'user';
				} else {
					this.obligeeType = 'company';
				}

			}
		}
	}
</script>

<style lang='scss' scoped>
	.container-right {
		padding: 0 32px 0;
	}

	.container-right-name {
		font-size: 24px;
		font-weight: 600;
		color: rgba(0, 18, 22, 0.85);
		line-height: 36px;
		padding: 32px 0;

		.line {
			width: 100%;
			height: 2px;
			margin-top: 24px;
			background: rgba(0, 0, 0, 0.02);
		}
	}

	.blockReminder {
		width: 664px;
		background: rgba(255, 231, 186, 0.2);
		border: 1px solid #FFD591;
		padding: 16px;

		.h3 {
			font-size: 16px;
			font-weight: 600;
			color: rgba(0, 18, 22, 0.65);
			margin-bottom: 8px;
		}

		.description {
			font-size: 12px;
			font-weight: 400;
			color: rgba(0, 18, 22, 0.5);
		}
	}

	.clear {
		clear: both;
		display: block;
	}

	.pageRNenter {
		.block_tab {
			margin-bottom: 48px;

			.tab {
				float: left;
				width: 320px;
				height: 160px;
				background: #F7F9FA;
				text-align: center;
			}

			.tab:nth-child(2) {
				margin-left: 24px;
			}

			.ant-btn {
				margin: 64px auto;
				line-height: 30px;
				box-sizing: border-box;
			}
		}
	}

	.resultPersonal {
		.resultwrap {
			margin-bottom: 16px;
			padding: 24px 24px 24px 80px;
			box-sizing: border-box;
			background: #F5FDFD;
			border-radius: 2px;
			border: 1px solid #D3DCE6;
			position: relative;
			font-size: 16px;
			font-weight: 600;
			color: rgba(0, 18, 22, 0.65);
			line-height: 24px;

			.icon {
				color: #0A4FD1;
				font-size: 40px;
				position: absolute;
				top: 24px;
				left: 24px;
			}
		}

		.IDcardwrap {
			.IDcard {
				width: 200px;
				height: 140px;
				background: #F9FAFC;
				border-radius: 4px;
				border: 1px solid #C0CCDA;
				display: inline;
				overflow: hidden;
			}

			.IDcard:first-child {
				margin-right: 16px;
			}

			.IDcard img{
				width: 200px;
				height: 140px;
			}
		}
	}

	.resultCompany {
		.resultwrap {
			margin-bottom: 16px;
			padding: 24px 24px 24px 80px;
			box-sizing: border-box;
			background: #F5FDFD;
			border-radius: 2px;
			border: 1px solid #D3DCE6;
			position: relative;
			font-size: 16px;
			font-weight: 600;
			color: rgba(0, 18, 22, 0.65);
			line-height: 24px;

			.icon {
				color: #0A4FD1;
				font-size: 40px;
				position: absolute;
				top: 24px;
				left: 24px;
			}
		}

		.BusinessLicensewrap {
			.BusinessLicense {
				width: 132px;
				height: 180px;
				background: #F9FAFC;
				border-radius: 4px;
				border: 1px solid #C0CCDA;
				overflow: hidden;
				img{
					width: 100%;
				}
			}

			.IDcard:first-child {
				margin-right: 16px;
			}
		}

	}
</style>
